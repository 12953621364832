@import url(https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i);
#root {
  min-height: 100vh;
}

#root>section {
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(128, 126, 126, 0.767) 10%, #8c9b9cc7 25%, #8eb8bd 40%, #92d1d8 80%, #93DCE3 100%);
}

body {
  height: 100%;
  margin: 0;
  font-family: Nunito,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-c);
  font-weight: 400;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-component {
  font-family: Nunito,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji" !important;
}

a {
  color : var(--text-color)
}

a:hover {
  color: var(--primary-color)
}

.app-sidebar a,
 .p-datatable-tbody{
  font-size: .75rem;
}

#logo {
  padding-top: 2rem;
  padding-left: 1rem;
}

.sidebar-nav a {
  text-decoration: none;
}

.sidebar-nav a.active {
  color: var(--cyan-500) !important;
  background-color: var(--cyan-50);
  border-color: var(--purple-500) !important;
}

.sidebar-nav.main  a.active {
  border-right: 2px solid !important;
}

.sidebar-nav.admin a.active {
  border-left: 2px solid !important;
}

.p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-datatable-scrollable .p-datatable-thead>tr>th,
.p-datatable-scrollable .p-datatable-tbody>tr>td,
.p-datatable-scrollable .p-datatable-tfoot>tr>td {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes noise-anim {
  0% {
    clip: rect(49px, 9999px, 40px, 0);
  }

  5% {
    clip: rect(75px, 9999px, 72px, 0);
  }

  10% {
    clip: rect(97px, 9999px, 93px, 0);
  }

  15% {
    clip: rect(15px, 9999px, 9px, 0);
  }

  20% {
    clip: rect(14px, 9999px, 92px, 0);
  }

  25% {
    clip: rect(18px, 9999px, 94px, 0);
  }

  30% {
    clip: rect(17px, 9999px, 20px, 0);
  }

  35% {
    clip: rect(71px, 9999px, 59px, 0);
  }

  40% {
    clip: rect(42px, 9999px, 84px, 0);
  }

  45% {
    clip: rect(56px, 9999px, 25px, 0);
  }

  50% {
    clip: rect(46px, 9999px, 14px, 0);
  }

  55% {
    clip: rect(47px, 9999px, 1px, 0);
  }

  60% {
    clip: rect(64px, 9999px, 58px, 0);
  }

  65% {
    clip: rect(89px, 9999px, 92px, 0);
  }

  70% {
    clip: rect(56px, 9999px, 39px, 0);
  }

  75% {
    clip: rect(80px, 9999px, 71px, 0);
  }

  80% {
    clip: rect(8px, 9999px, 13px, 0);
  }

  85% {
    clip: rect(66px, 9999px, 68px, 0);
  }

  90% {
    clip: rect(68px, 9999px, 4px, 0);
  }

  95% {
    clip: rect(56px, 9999px, 14px, 0);
  }

  100% {
    clip: rect(28px, 9999px, 53px, 0);
  }
}

@keyframes noise-anim {
  0% {
    clip: rect(49px, 9999px, 40px, 0);
  }

  5% {
    clip: rect(75px, 9999px, 72px, 0);
  }

  10% {
    clip: rect(97px, 9999px, 93px, 0);
  }

  15% {
    clip: rect(15px, 9999px, 9px, 0);
  }

  20% {
    clip: rect(14px, 9999px, 92px, 0);
  }

  25% {
    clip: rect(18px, 9999px, 94px, 0);
  }

  30% {
    clip: rect(17px, 9999px, 20px, 0);
  }

  35% {
    clip: rect(71px, 9999px, 59px, 0);
  }

  40% {
    clip: rect(42px, 9999px, 84px, 0);
  }

  45% {
    clip: rect(56px, 9999px, 25px, 0);
  }

  50% {
    clip: rect(46px, 9999px, 14px, 0);
  }

  55% {
    clip: rect(47px, 9999px, 1px, 0);
  }

  60% {
    clip: rect(64px, 9999px, 58px, 0);
  }

  65% {
    clip: rect(89px, 9999px, 92px, 0);
  }

  70% {
    clip: rect(56px, 9999px, 39px, 0);
  }

  75% {
    clip: rect(80px, 9999px, 71px, 0);
  }

  80% {
    clip: rect(8px, 9999px, 13px, 0);
  }

  85% {
    clip: rect(66px, 9999px, 68px, 0);
  }

  90% {
    clip: rect(68px, 9999px, 4px, 0);
  }

  95% {
    clip: rect(56px, 9999px, 14px, 0);
  }

  100% {
    clip: rect(28px, 9999px, 53px, 0);
  }
}
.bg-login-image {
    background: url(/static/media/login_logo.3e049512.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.login-card {
    display: flex;
    min-height: 500px;
    justify-content: center;
    flex-direction: column;
}
.dash_banner {
    background-image: linear-gradient(180deg, rgb(128 126 126 / 29%) 10%, #96989896 25%, #8eb8bd 40%, #3c777de3 80%, #3b929a3b 100%);
}

.os-selection .p-card-content {
text-align: center;
    color: white;
    font-size: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.p-card.os-win  {
    background-color: deepskyblue;
}

.p-card.os-cen  {
    background-color: gold;
}

.p-card.os-ubu  {
    background-color: coral;
}

.p-card.os-arc {
    background-color: steelblue;
}

.p-card.os-no {
    background-color: #93dce3;
}
