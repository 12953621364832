
.os-selection .p-card-content {
text-align: center;
    color: white;
    font-size: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.p-card.os-win  {
    background-color: deepskyblue;
}

.p-card.os-cen  {
    background-color: gold;
}

.p-card.os-ubu  {
    background-color: coral;
}

.p-card.os-arc {
    background-color: steelblue;
}

.p-card.os-no {
    background-color: #93dce3;
}